.screen-settings {
  .setting {
    margin-bottom: 15px;
  }

  .label {
    display: inline-block;
    padding-right: 15px;
    min-width: 130px;
  }
}
