.take-image-div {
  display: block;
  position: relative;
  width: 100%;
  margin: 0px auto;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0.4;
  }

  .cam {
    width: 100%;
  }
}
