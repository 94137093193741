.diff-view {
  .type-chooser {
    text-align: center;
    margin: 20px auto;
  }

  .instructions {
    text-align: center;
  }

  .crossfade {
    margin-bottom: 20px;

    .slider-div {
      display: flex;

      .rc-slider {
        flex: 1;
        margin: 5px 15px;
        align-content: center;
      }
    }
  }
}
