.step-content {
  h2 {
    text-align: center;
  }
}

.step-between {
  .important-hint {
    font-weight: bold;
  }

  img {
    margin-top: 30px;
  }
}

.image-upload {
  .file-select {
    input {
      display: none;
    }
  }
}
