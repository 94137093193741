$big-screen: "only screen and (min-width: 768px)";
$medium-screen: "only screen and (min-width: 500px)";

@import "main_menu";
@import "step_list";
@import "webcam";
@import "settings";
@import "compare";
@import "step_content";
@import "privacy";

#root {
  background: eee;

  .app {
    margin: 10px 5px;

    @media #{$big-screen} {
      width: 90%;
      margin: 10px auto;
    }
  }

  .screen-instructions,
  .screen-settings,
  .screen-privacy {
    .button {
      display: block;
      margin: 20px auto 0px auto;
    }
  }

  code, .code {
    background-color: lightgray;
    padding: 2px 5px;
    border-radius: 4px;
  }

  img {
    width: 100%;
  }

  .bold {
    font-weight: bold;
  }

  .err-msg {
    border-radius: 5px;
    background-color: #ffaaaa;
    border: 2px solid #f00;
    padding: 3px;
    text-align: center;
    margin: 20px auto;
  }

  .diff-view {
    img {
      display: block;
      margin: 20px auto 0px auto;
      width: 100%;
    }
  }

  .overlay-container {
    position: relative;

    .on-top {
      position: absolute;
      margin: 0px;
      top: 0;
      left: 0;
    }
  }
}
