$button-fg: #444;
$button-bg: white;

%button-style {
  padding: 15px 5px;
  margin: 0px 5px;
  border: 2px solid $button-fg;
  border-radius: 10px;
  text-align: center;
  background-color: $button-bg;
  color: black;
  font-size: 25px;
  box-sizing: border-box;

  &:hover {
    background-color: $button-fg;
    color: $button-bg;
  }

  &.disabled {
    color: gray;

    &:hover {
      background-color: white;
      cursor: not-allowed;
    }
  }

  @media #{$medium-screen} {
    font-size: 20px;
  }

  @media #{$big-screen} {
    padding: 5px 5px;
    margin: 0px 15px;
    font-size: 15px;
  }
}

.button, button {
  display: block;
  width: 200px;
  margin: 30px 20px 15px 20px;
  @extend %button-style;
}


.step-header .list {
  display: none;
  padding: 0px;
  margin: 0px auto;

  li {
    flex: 1;
    display: inline-block;
    @extend %button-style;

    &:first-child {
      margin-left: 0px;
    }

    &:last-child {
      margin-right: 0px;
    }

    &.disabled {
      display: none;

      @media #{$big-screen} {
        display: block;
      }
    }
  }

  .selected {
    color: blue;
  }

  .selectable {
    cursor: pointer;
  }
}

.step-header .buttons {
  display: flex;
  margin: 0px 10px;

  button {
    flex: 1;
  }
}

@media #{$medium-screen} {
  .step-header {
    .list {
      display: flex;
    }

    .buttons {
      display: none;
    }
  }
}
